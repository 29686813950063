import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Subtitle } from "../Subtitle";
import { labels } from "../../constants";
import PropTypes from "prop-types";

const Locations = ({ locations, columns }) => {
  const cols = columns?.map((val) => ({ ...val, flex: 1 }));
  const colsForAutoSize = cols?.map((item) => item.field);
  return (
    <>
      {cols && (
        <Box>
          <Subtitle text={labels.locationsToBeEnabled} />
          <DataGrid
            autosizeOptions={{
              columns: colsForAutoSize,
              includeOutliers: true,
              includeHeaders: false,
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            getRowId={(row) => row[colsForAutoSize[0]]}
            rows={locations}
            columns={cols}
            disableRowSelectionOnClick
          />
        </Box>
      )}
    </>
  );
};

Locations.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default Locations;
