import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import ChatBox from "./Chatbox";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { Subtitle } from "../../components/Subtitle";
import { labels } from "../../constants/labels";
import axios from "axios";
import ExcelViewer from "./ExcelViewer";

const Query = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [references, setReferences] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAi, setOpenAi] = useState(false);
  const [sqlAgent, setSqlAgent] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const aiModelOptions = [
    { label: "Open AI", value: "Open AI" },
    { label: "Gemini", value: "Gemini" },
  ];

  const [aiModel, setAiModel] = useState(aiModelOptions[0].value);

  const handleAiModelChange = (event) => {
    setAiModel(event.target.value);
  };

  const rfpName = useSelector((state) => state.tabs.rfpName);
  const rfpData = useSelector((state) => state.rfpData.rfpData);

  const isReferencesEmpty = references?.length === 0;

  const dispatch = useDispatch();

  const handleOpenAIChange = () => {
    setOpenAi(!openAi);
  };

  const handleSqlAgentChange = () => {
    setSqlAgent(!sqlAgent);
  };

  useEffect(() => {
    dispatch(setActiveTab("query"));
  }, [dispatch]);

  const handleSuggestionClick = (suggestion) => {
    handleSend(suggestion);
  };

  const handleSend = async (suggestion) => {
    if (input.trim() || suggestion) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: suggestion ? suggestion : input,
          fromSender: true,
          loading: false,
        },
      ]);
      setInput("");

      try {
        setIsLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_QUERY_API}/query`,
          {
            query: suggestion ? suggestion : input,
            rfp_name: rfpName,
            sql_agent: !sqlAgent,
            open_ai: aiModel === aiModelOptions[0].value,
          }
        );

        const apiResponse = {
          fromSender: false,
          text: response?.data?.data,
        };
        setReferences(response?.data?.references);

        setMessages((prevHistory) => [...prevHistory, apiResponse]);
      } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = {
          fromSender: false,
          text: "Something went wrong. Please try again later.",
        };
        setMessages((prevHistory) => [...prevHistory, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClickOpen = (fileUrl) => {
    setOpen(true);
    setFileUrl(fileUrl);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Subtitle text={labels.queryPageTitle} />
      <Typography color="textPrimary" gutterBottom variant="body2">
        {labels.queryPageDescription}
      </Typography>

      <Grid container spacing={2}>
        <Grid size={isReferencesEmpty ? 12 : 8}>
          <Paper elevation={2}>
            <ChatBox
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              messages={messages}
              setMessages={setMessages}
              input={input}
              setInput={setInput}
              handleSend={handleSend}
              openAi={openAi}
              sqlAgent={sqlAgent}
              handleOpenAIChange={handleOpenAIChange}
              handleSqlAgentChange={handleSqlAgentChange}
              handleAiModelChange={handleAiModelChange}
              aiModel={aiModel}
            />
          </Paper>
        </Grid>
        {!isReferencesEmpty && (
          <Grid size={4}>
            <Paper sx={{ height: 300, width: "100%" }}>
              <Typography
                sx={{ pl: 2, pt: 2, mb: 2 }}
                variant="body2"
                fontWeight={500}
              >
                References
              </Typography>
              {references?.map(({ fileName, fileUrl }, index) => (
                <Chip
                  key={index}
                  sx={{ p: 1, ml: 1, mr: 1, mb: 1, height: "fit-content" }}
                  onClick={() => handleClickOpen(fileUrl)}
                  label={
                    <Typography variant="caption" sx={{ textWrap: "initial" }}>
                      {fileName}
                    </Typography>
                  }
                />
              ))}
            </Paper>
          </Grid>
        )}
        <Grid size={6}>
          {rfpData?.common_question?.length > 0 && (
            <Typography mb={2} variant="body2" fontWeight={500}>
              FAQs
            </Typography>
          )}
          <Grid container spacing={1}>
            {rfpData?.common_question?.map((suggestion, index) => (
              <Grid key={index} size={4}>
                <CardActionArea
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      display: "flex",
                      columnGap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{suggestion}</Typography>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid size={6}>
          {rfpData?.questions?.length > 0 && (
            <Typography mb={2} variant="body2" fontWeight={500}>
              AI generated questions
            </Typography>
          )}
          <Grid container spacing={1}>
            {rfpData?.questions?.map((suggestion, index) => (
              <Grid key={index} size={4}>
                <CardActionArea
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      display: "flex",
                      columnGap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{suggestion}</Typography>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Reference</DialogTitle>
        <DialogContent>
          <ExcelViewer fileUrl={fileUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Query;
