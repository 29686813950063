import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { grey } from "@mui/material/colors";
import { Locations } from "../../components/Locations";
import { LegalClauses } from "../../components/LegalClauses";
import { ProductsRequested } from "../../components/ProductsRequested";
import { labels } from "../../constants";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { useData } from "../../hooks/useData";
import WorksheetSummary from "./WorksheetSummary";
import ReplayIcon from "@mui/icons-material/Replay";
import ChatIcon from "@mui/icons-material/Chat";

const Analyze = () => {
  const [shouldFetchDetails, setShouldFetchDetails] = useState(false);
  const [rfpStatusText, setRfpStatusText] = useState("");
  const [shouldReanalyze, setShouldReanalyze] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const rfpName = location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(setActiveTab("analyze"));
  }, [dispatch]);

  const { rfpData, loading, error, reanalyzeLoading } = useData(
    rfpName,
    shouldFetchDetails ? "analyze" : "status",
    shouldFetchDetails,
    shouldReanalyze
  );

  const handleReanalyzeClick = () => {
    setShouldReanalyze(true); // Trigger reanalysis
  };

  useEffect(() => {
    if (shouldReanalyze) {
      setShouldReanalyze(false); // Reset the state after reanalyze starts
    }
  }, [shouldReanalyze]);

  useEffect(() => {
    if (rfpData === 1 || rfpData?.rfp_name) {
      setRfpStatusText("");
      setShouldFetchDetails(true);
    } else {
      setRfpStatusText(labels.rfpProcessing);
    }
  }, [rfpData]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (loading) {
    return <CircularProgress size={20} />;
  }

  if (reanalyzeLoading) {
    return (
      <Alert
        color="info"
        icon={<CircularProgress sx={{ color: "white" }} size={20} />}
        variant="filled"
      >
        {labels.rfpReanalyzing}
      </Alert>
    );
  }

  const handleQueryClick = () => {
    navigate(`/query/${rfpName}`);
  };

  return (
    <>
      {rfpStatusText ? (
        <Alert
          color="info"
          icon={<CircularProgress sx={{ color: "white" }} size={20} />}
          variant="filled"
        >
          {rfpStatusText}
        </Alert>
      ) : (
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid size={6}>
            <Typography variant="h6" fontWeight={600}>
              {rfpData?.rfp_name || "RFP name"}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Box
              sx={{
                display: "flex",
                columnGap: 2,
                alignItems: "center",
                float: "right",
              }}
            >
              <Typography color={grey[800]} variant="body2" fontWeight={500}>
                {labels.rfpDue} {rfpData?.due_date || "Not available"}
              </Typography>
              <Box sx={{ display: "flex", columnGap: 1 }}>
                <Button startIcon={<ChatIcon />} onClick={handleQueryClick}>
                  {labels.askRfpAssistant}
                </Button>
                <Button
                  startIcon={<ReplayIcon />}
                  onClick={handleReanalyzeClick}
                >
                  Reanalyze
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid size={12}>
            <Divider sx={{ borderWidth: 1, borderColor: grey[600] }} />
          </Grid>
          <Grid size={12}>
            <WorksheetSummary rfpData={rfpData} />
          </Grid>
          {rfpData?.product && (
            <Grid size={12}>
              <ProductsRequested
                products={rfpData?.product?.data}
                columns={rfpData?.product?.columns}
              />
            </Grid>
          )}
          {rfpData?.location && (
            <Grid size={12}>
              <Locations
                locations={rfpData?.location?.data}
                columns={rfpData?.location?.columns}
              />
            </Grid>
          )}
          {rfpData?.legal && (
            <Grid size={12}>
              <LegalClauses
                legalClauses={rfpData?.legal?.data}
                columns={rfpData?.legal?.columns}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Analyze;
