import { useState, useEffect } from "react";
import { getRfpData, reanalyzeRfp } from "../apis/rfp";
import { useDispatch } from "react-redux";
import { setRfpData } from "../redux/slices/rfpData";
import { errors } from "../constants";

export const useData = (rfpName, key, shouldFetchDetails, shouldReanalyze) => {
  const [rfpData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reanalyzeLoading, setReanalyzeLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let intervalId;
    let timeoutId;

    const fetchdata = async () => {
      try {
        setLoading(true);
        const data = await getRfpData(rfpName, key);
        setData(data);
        dispatch(setRfpData(data));

        if (data === 1 || data?.rfp_name) {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        }
      } catch (err) {
        if (err.status === 500) {
          setError(errors.somethingWentWrong);
        } else {
          setError(err.response?.data?.error || "An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    const reanalyze = async () => {
      try {
        setReanalyzeLoading(true);
        await reanalyzeRfp(rfpName); // Wait for reanalyze to complete
        fetchdata(); // Fetch data only after reanalysis is successful
      } catch (err) {
        setError("Failed to reanalyze. Please try again.");
      } finally {
        setReanalyzeLoading(false);
      }
    };

    // Trigger reanalyze if shouldReanalyze is true
    if (shouldReanalyze) {
      reanalyze();
    } else {
      // Initial fetch
      fetchdata();

      // Set interval for polling
      intervalId = setInterval(() => {
        fetchdata();
      }, 5000); // Every 5 seconds

      // Set timeout to stop polling after 5 minutes (300000 ms)
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setError(
          "The file could not be analyzed. Please reupload or use another file."
        );
      }, 300000);
    }

    // Cleanup function to clear interval and timeout
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [rfpName, key, shouldFetchDetails, shouldReanalyze, dispatch]);

  return { rfpData, loading, reanalyzeLoading, error };
};
