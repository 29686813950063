import axios from "axios";
import httpClient from "../services/httpClient";

export const getRfpDetails = async (rfpName) => {
  try {
    const response = await httpClient.get(`rfp_details/${rfpName}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRfpUploadStatus = async (rfpName) => {
  try {
    const response = await httpClient.get(`get_status/${rfpName}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSuggestions = async (rfpName) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SUGGESTIONS_API}/questions/${rfpName}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRfpData = async (rfpName, key) => {
  const payload = {
    key: key,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_GET_DATA}/${rfpName}`,
      payload
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const reanalyzeRfp = async (rfpName) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REANALYZE}/${rfpName}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
