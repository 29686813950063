import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Avatar, Button, Container } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setLoginStatus } from "../../redux/slices/login";
import { grey } from "@mui/material/colors";
import { setActiveTab, setRfpName } from "../../redux/slices/tabs";
import { auth } from "../../pages/Login/firebase";
import { labels, tabs } from "../../constants";
import { setUser } from "../../redux/slices/user";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreospanLogo from "../../images/creospan.png";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tabs.activeTab);

  const rfpName = useSelector((state) => state.tabs.rfpName);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const user = useSelector((state) => state.user.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    dispatch(setActiveTab(newValue));
  };

  const handleTabClick = (path) => {
    if (path !== "upload") {
      navigate(`${path}/${rfpName}`);
    } else {
      navigate(path);
    }
  };

  const handleLogout = () => {
    dispatch(setUser({}));
    dispatch(setLoginStatus(false));
    dispatch(setRfpName(""));
    // Optionally, sign out from Firebase as well
    auth.signOut();
    navigate("/login"); // Redirect to login after logout
  };

  const handleProfileClick = () => {
    navigate("/profile");
    handleClose();
  };

  const { pathname } = useLocation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <img
            style={{ height: 40, width: 40 }}
            src={CreospanLogo}
            alt="logo"
          />
          <Typography
            onClick={() => navigate("/upload")}
            variant="h6"
            component="div"
            sx={{ ml: 1, flexGrow: 1, cursor: "pointer" }}
          >
            {labels.appName}
          </Typography>
          <IconButton
            id="user-avatar"
            size="large"
            aria-label="menu"
            onClick={handleClick}
          >
            <Avatar sx={{ bgcolor: grey[200], color: grey[900] }}>
              {user?.email?.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "user-avatar",
            }}
            slotProps={{
              paper: { sx: { width: 200 } },
            }}
          >
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        {pathname === "/profile" ? (
          <Button
            sx={{ mt: 10, mb: 2, ml: 3 }}
            onClick={() => {
              navigate(-1);
            }}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        ) : (
          <Box sx={{ width: "100%", mt: 8, mb: 2 }}>
            <Tabs value={activeTab} onChange={handleChange} aria-label="tabs">
              {tabs.map((tab, index) => (
                <Tab
                  icon={tab.icon}
                  iconPosition="start"
                  sx={{ textTransform: "none" }}
                  key={index}
                  value={tab.value}
                  label={tab.label}
                  disabled={pathname.split("/")[2] ? false : tab.disabed}
                  onClick={() => handleTabClick(tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Header;
