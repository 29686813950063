import React from "react";
import { Grid2 as Grid, Paper, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import PlaceIcon from "@mui/icons-material/Place";
import InventoryIcon from "@mui/icons-material/Inventory";
import PolicyIcon from "@mui/icons-material/Policy";
import { grey } from "@mui/material/colors";
import { Subtitle } from "../../components/Subtitle";

const WorksheetSummary = ({ rfpData }) => {
  return (
    <>
      <Subtitle text={"RFP summary"} />
      <Typography color="textPrimary" variant="body2" mb={2}>
        Your data has been thoroughly processed by RFP Navigator, covering
        essential aspects for informed decision-making.
      </Typography>
      <Paper elevation={2} sx={{ bgcolor: grey[50], height: "100%", p: 2 }}>
        <Grid container textAlign="center">
          <Grid size={3}>
            <DescriptionIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b>{rfpData?.n_worksheets}</b> Worksheet(s)
            </Typography>
          </Grid>
          <Grid size={3}>
            <PlaceIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b>{rfpData?.n_location}</b> Location(s)
            </Typography>
          </Grid>
          <Grid size={3}>
            <InventoryIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b>{rfpData?.n_products}</b> Product(s)
            </Typography>
          </Grid>
          <Grid size={3}>
            <PolicyIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b>{rfpData?.n_legal}</b> Legal clause(s)
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default WorksheetSummary;
