import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Paper,
  Typography,
  ListItemText,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Grid2 as Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { aiModelOptions } from "../../utils/menus";
import SendIcon from "@mui/icons-material/Send";

const ChatBox = ({
  handleSend,
  input,
  setInput,
  isLoading,
  messages,
  handleSqlAgentChange,
  handleAiModelChange,
  aiModel,
}) => {
  const [listHeight, setListHeight] = useState(0);

  const endOfMessagesRef = useRef(null);

  const rfpName = useSelector((state) => state.tabs.rfpName);

  const calculateListHeight = () => {
    const availableHeight = 300;
    setListHeight(availableHeight);
  };

  useEffect(() => {
    calculateListHeight();
    window.addEventListener("resize", calculateListHeight);
    return () => {
      window.removeEventListener("resize", calculateListHeight);
    };
  }, []);

  useEffect(() => {
    if (messages.length > 2) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <>
      {isLoading && <LinearProgress color="secondary" />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: listHeight,
        }}
      >
        <Box flexGrow={1} overflow="auto">
          <List>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: message.fromSender
                    ? "flex-end"
                    : "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    backgroundColor: message.fromSender ? teal[50] : grey[100],
                    borderRadius: 1,
                    maxWidth: "75%",
                  }}
                >
                  <ListItemText
                    sx={{
                      textWrap: "wrap",
                    }}
                  >
                    <Typography variant="body2">{message.text}</Typography>
                  </ListItemText>
                </Paper>
              </ListItem>
            ))}
            <div ref={endOfMessagesRef} />
          </List>
        </Box>

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSend();
          }}
        >
          <Grid container spacing={2} sx={{ mb: 1, ml: 2, mr: 2 }}>
            <Grid item size={6}>
              <TextField
                size="small"
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={`Ask anything about ${rfpName}`}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "0.875rem" },
                }}
              />
            </Grid>
            <Grid item size={2}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={handleSqlAgentChange} />}
                  label={
                    <Typography variant="body2">In-depth analysis</Typography>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item size={2}>
              <FormControl fullWidth>
                <InputLabel size="small" id="aiOptions">
                  <Typography variant="body2">AI Model</Typography>
                </InputLabel>
                <Select
                  size="small"
                  labelId="aiOptions"
                  id="aiOptions-menu"
                  value={aiModel}
                  label={<Typography variant="body2">AI Model</Typography>}
                  onChange={handleAiModelChange}
                >
                  {aiModelOptions.map((item, index) => (
                    <MenuItem
                      sx={{ fontSize: "0.875rem" }}
                      key={index}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item size={2}>
              <Button
                fullWidth
                startIcon={<SendIcon />}
                size="medium"
                type="submit"
                color="primary"
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ChatBox;
