import {
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
  getMetadata,
} from "firebase/storage";
import { storage } from "../pages/Login/firebase";

export const getFilesFromStorage = async () => {
  const storage = getStorage();
  const storageRef = ref(storage, "uploads");

  try {
    const response = await listAll(storageRef);
    const resItems = response.items;

    // Wait for all metadata to be fetched
    const metadataList = await Promise.all(
      resItems.map((fileRef) => getMetadata(fileRef))
    );

    // Combine file references with their metadata
    const sortedFiles = resItems
      .map((fileRef, index) => ({
        fileRef,
        metadata: metadataList[index],
      }))
      .sort((a, b) => {
        // Sort in descending order by last modified date
        return new Date(b.metadata.updated) - new Date(a.metadata.updated);
      });

    // Map the sorted files to desired format
    const files = sortedFiles.map((item) => ({
      name: item.metadata.name,
      id: item.fileRef.fullPath,
    }));

    return files;
  } catch (error) {
    console.error("Error fetching files:", error);
    throw error;
  }
};

export const uploadFileToStorage = (file, setProgress) => {
  const storageRef = ref(storage, `uploads/${file.name}`);

  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(progress);
    },
    (error) => {
      console.error("Upload failed", error);
    },
    () => {}
  );
};
